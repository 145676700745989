import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import Layout from '../components/layout'
import * as styles from '../styles/production.module.css'

const Production = () => {
  const breakpoints = useBreakpoint()

  return (
    <Layout
      isHome={false}
      title="クリエイティブ制作"
      description="「事業の成長」と「ブランディングの加速」を応援します！サブスクリプション期間内は、文言・画像を差し替えし放題。月に1度のヒアリング＆コーチング付き。"
    >
      <div className={styles.productionContainer}>
        <h1 className={styles.productionTitleEn}>Creative Production</h1>
        <h2 className={styles.productionTitleJp}>クリエイティブ制作</h2>
        <StaticImage
          className={styles.productionBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        />
        <div className={styles.productionDescriptionWrapper}>
          <div className={styles.productionDescriptionUpper}>
            <div className={styles.productionDescriptionRow}>
              <h2 className={styles.productionDescriptionTitle}>
                「事業の成長」と「ブランディングの加速」を応援します！
              </h2>
              <div className={styles.productionDescriptionTip}>
                <StaticImage
                  className={styles.productionDescriptionTipIcon}
                  alt="√"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionDescriptionText}>
                  立ち上げ時にあまり<strong>時間をかけられない</strong>
                </p>
              </div>
              <div className={styles.productionDescriptionTip}>
                <StaticImage
                  className={styles.productionDescriptionTipIcon}
                  alt="√"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionDescriptionText}>
                  <strong>SEO対策</strong>や<strong>スマホ対応</strong>など、運用も任せたい
                </p>
              </div>
              <div className={styles.productionDescriptionTip} style={{margin: "0"}}>
                <StaticImage
                  className={styles.productionDescriptionTipIcon}
                  alt="√"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionDescriptionText}>
                  制作して終わりではなく<strong>自由に修正・変更</strong>したい
                </p>
              </div>
            </div>
            <StaticImage
              className={styles.productionDescriptionImage}
              alt="クリエイティブ制作"
              src="../images/blobs-creative-production.png"
            />
          </div>
          <div className={styles.productionDescriptionLower}>
            <div className={styles.productionDescriptionRow}>
              <p className={styles.productionDescriptionText}>
                「NEUTRAL」ではそんな方におすすめの<strong>サブスクリプション</strong>プランをご用意しています。<br/>
                私たちは<strong>新しいことにチャレンジ</strong>する方を応援します！
              </p>
            </div>
            <p className={styles.productionDescriptionNotice}>
              新規受付を締め切りました。<br />お問い合わせは
              <Link to="/#contact" className={styles.productionDescriptionNotice}>こちら</Link>
            </p>
          </div>
          {/* <div className={styles.productionLineButtonWrapper}>
            <a className={styles.productionLineButton} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
              無料でLINE相談してみる
            </a>
          </div> */}
        </div>
        {/* <StaticImage
          className={styles.productionBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        <div id="omakase" className={styles.productionCourseWrapper}>
          <div className={styles.productionCourseCard}>
            <h2 className={styles.productionCourseTitle}>
              Webサイトおまかせコース
            </h2>
            <div className={styles.productionCourseTagsGrid}>
              <div className={styles.productionCourseTag}>
                <StaticImage
                  className={styles.productionCourseTagIcon}
                  alt="運用費無料"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionCourseTagText}>運用費無料</p>
              </div>
              <div className={styles.productionCourseTag}>
                <StaticImage
                  className={styles.productionCourseTagIcon}
                  alt="SEO対策"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionCourseTagText}>SEO対策</p>
              </div>
              <div className={styles.productionCourseTag}>
                <StaticImage
                  className={styles.productionCourseTagIcon}
                  alt="文言・画像差し替えし放題"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionCourseTagText}>文言・画像差し替えし放題</p>
              </div>
              <div className={styles.productionCourseTag}>
                <StaticImage
                  className={styles.productionCourseTagIcon}
                  alt="毎月ヒアリング・コーチング"
                  src="../images/icon-check.png"
                />
                <p className={styles.productionCourseTagText}>ヒアリング・コーチング</p>
              </div>
            </div>
            <div className={styles.productionCoursePoint}>
              <div className={styles.productionCoursePointHeader}>
                <StaticImage
                  className={styles.productionCoursePointCushion}
                  alt="こんな人におすすめ"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.productionCoursePointText}>こんな人におすすめ</h2>
              </div>
              <div className={styles.productionCoursePointBody}>
                <p className={styles.productionCoursePointBodyText}>
                  <strong>新しく事業を立ち上げる人、ホームページが欲しいけど費用を抑えたい人</strong>
                </p>
              </div>
            </div>
            <div className={styles.productionCoursePoint}>
              <div className={styles.productionCoursePointHeader}>
                <StaticImage
                  className={styles.productionCoursePointCushion}
                  alt="おすすめポイント"
                  src="../images/cushion-arrow.png"
                />
                <h2 className={styles.productionCoursePointText}>おすすめポイント</h2>
              </div>
              <div className={styles.productionCoursePointBody}>
                <div className={styles.productionCoursePointBodyRow} style={{margin: "0 0 5px"}}>
                  <div className={styles.productionCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.productionCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.productionCoursePointBodyText}>
                    サブスクリプション期間内は、文言・画像を<strong>差し替えし放題</strong>
                  </p>
                </div>
                <div className={styles.productionCoursePointBodyRow}>
                  <div className={styles.productionCoursePointBodyIconWrapper}>
                    <StaticImage
                      className={styles.productionCoursePointBodyIcon}
                      alt="√"
                      src="../images/icon-check.png"
                    />
                  </div>
                  <p className={styles.productionCoursePointBodyText}>
                    月に1度の<strong>ヒアリング＆コーチング付き</strong>で、成長のお手伝い
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.productionPriceCard}>
              <div className={styles.productionPricePopupWrapper}>
                <div className={styles.productionPricePopup}>
                  <StaticImage
                    className={styles.productionPricePopupCushion}
                    alt="始めやすいお手頃料金"
                    src="../images/cushion-fukidashi.png"
                  />
                  <p className={styles.productionPricePopupText}>始めやすい<br/><strong>お手頃料金</strong></p>
                </div>
              </div>
              <div className={styles.productionPriceLabel}>
                <span className={styles.productionPriceLabelText}>料金プラン</span>
              </div>
              <p className={styles.productionPriceText}>
                月額<span className={styles.productionPriceBodyTextLarge}>6,000</span>円
                {breakpoints.xs || breakpoints.sm || breakpoints.md ? <br/> : " / "}
                <span style={breakpoints.xs || breakpoints.sm || breakpoints.md ? {fontSize: "13px"} : {fontSize: "16px"}}>(1年間サブスクリプション)</span>
              </p>
              <p className={styles.productionPriceText}>
                入会金<span className={styles.productionPriceBodyTextLarge}>30,000</span>円<span style={{fontWeight: "normal"}}> (税込)</span>
              </p>
              <hr className={styles.productionPriceHr}/>
              <div className={styles.productionPriceBodyRow}>
                <div className={styles.productionPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.productionPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.productionPriceBodyText}>
                  <strong>運用費無料</strong> (ドメイン代別)
                </p>
              </div>
              <div className={styles.productionPriceBodyRow}>
                <div className={styles.productionPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.productionPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.productionPriceBodyText}>
                  <strong>基本セット</strong>: 5セクション、MV、レスポンシブ対応、SEO対策
                </p>
              </div>
              <div className={styles.productionPriceBodyRow}>
                <div className={styles.productionPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.productionPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.productionPriceBodyText}>
                  <strong>オプションあり</strong> (下記「単品・オプション料金」参照)
                </p>
              </div>
              <div className={styles.productionPriceBodyRow}>
                <div className={styles.productionPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.productionPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.productionPriceBodyText}>
                  <strong>文言・画像差し替えし放題</strong> (制作を伴わないもの)
                </p>
              </div>
              <div className={styles.productionPriceBodyRow}>
                <div className={styles.productionPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.productionPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.productionPriceBodyText}>
                <strong>サブスクリプション終了後は譲渡</strong> (継続も可能 6,000円/月)
                </p>
              </div>
              <div className={styles.productionPriceBodyRow}>
                <div className={styles.productionPriceBodyIconWrapper}>
                  <StaticImage
                    className={styles.productionPriceBodyIcon}
                    alt="√"
                    src="../images/icon-check.png"
                  />
                </div>
                <p className={styles.productionPriceBodyText}>
                  <strong>月に1度のヒアリング＆コーチング付き</strong>
                </p>
              </div>
              <div className={styles.productionPriceButtonRow}>
                <Link to="/#contact" className={styles.productionPriceButton}>
                  お問い合わせ
                </Link>
                <Link to="/order" state={{ plan: "production" }} className={styles.productionPriceButton}>
                  お申し込み
                </Link>
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.productionBorderImage}
          alt="Border line"
          src="../images/curve-clear.png"
        />
        <div id="option" className={styles.productionCourseWrapper}>
          <div className={styles.productionCourseCard}>
            <h2 className={styles.productionCourseTitle}>
              単品・オプション料金
            </h2>
            <div className={styles.productionOptionGrid}>
              <div className={styles.productionOptionLeft}>
                <h3 className={styles.productionOptionTitle}>オリジナルWebサイト制作</h3>
                <table className={styles.productionOptionTable} rules="all">
                  <thead className={styles.productionOptionTableHeader}>
                    <tr>
                      <th className={styles.productionOptionTableHeaderText}>サイト種類</th>
                      <th className={styles.productionOptionTableHeaderText}>料金(税込)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>独自サイト</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥100,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>ノーコード(STUDIO)サイト</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥100,000</td>
                    </tr>
                  </tbody>
                </table>
                <p className={styles.productionOptionNotice}>
                  ＊どちらも基本セット(5セクション、MV、レスポンシブ対応、SEO対策)が含まれます。
                </p>
                <p className={styles.productionOptionNotice}>
                  ＊どちらも独自ドメイン希望の場合は自己負担。(年間¥1,000程度)
                </p>
                <p className={styles.productionOptionNotice}>
                  ＊ノーコードサイトは、
                  <a href="https://studio.design/ja" style={{fontWeight: "bold"}} target="_blank" rel="noopener noreferrer">STUDIO</a>
                  を利用します。STUDIOの運用費用は自己負担。(Starterプランの場合: ¥980/月)
                </p>
                <h3 className={styles.productionOptionTitle}>追加オプション</h3>
                <table className={styles.productionOptionTable} rules="all">
                  <thead className={styles.productionOptionTableHeader}>
                    <tr>
                      <th className={styles.productionOptionTableHeaderText}>アイテム</th>
                      <th className={styles.productionOptionTableHeaderText}>料金(税込)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>オリジナル画像/バナー</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥8,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>ロゴ (2色・ファビコン)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥25,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>ロゴ/アイコンのトレース</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥5,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>ブログ機能</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥10,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>EC機能</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥50,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>SNS連携 (1種)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥8,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>Googleマップ埋め込み</td>
                      <td className={styles.productionOptionTableBodyPrice}>無料</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>Googleアナリティクス導入</td>
                      <td className={styles.productionOptionTableBodyPrice}>無料</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>フォトギャラリー (10枚まで)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥5,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>スライドショー (5枚まで)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥5,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>フォーム</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥5,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>セクション/ページ追加</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥5,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>アニメーション (1種)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥5,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>文言執筆代行 (5セクションまで)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥20,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>翻訳 (日本語⇄英語)</td>
                      <td className={styles.productionOptionTableBodyPrice}>1文字あたり¥11〜</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>多言語化 (言語切り替え機能)</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥20,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>文言・画像差し替え</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥3,000</td>
                    </tr>
                    <tr>
                      <td className={styles.productionOptionTableBodyItem}>Insta Link</td>
                      <td className={styles.productionOptionTableBodyPrice}>¥10,000</td>
                    </tr>
                  </tbody>
                </table>
                <p className={styles.productionOptionNotice}>
                  ＊他にも追加可能な機能・オプションはございますので、お気軽に<strong>LINE</strong>または<strong>お問い合わせフォーム</strong>よりご連絡ください。
                </p>
              </div>
              <div className={styles.productionOptionPastWork}>
                <StaticImage
                  className={styles.productionOptionPastWorkImage}
                  alt="Mond（モーント）"
                  src="../images/mond-both.png"
                />
                <Link to="/past-work" className={styles.productionOptionPastWorkButton}>
                  制作実績を見る
                </Link>
              </div>
            </div>
          </div>
        </div>
        <StaticImage
          className={styles.productionBorderImage}
          alt="Border line"
          src="../images/curve-moss-top.png"
        /> */}
        <div className={styles.productionSupport}>
          <div className={styles.productionSupportInner}>
            <h2 className={styles.productionSupportTitle}>私たちが制作します！</h2>
            <p className={styles.productionSupportText}>
              「NEUTRAL」はみなさまの<strong>新たなチャレンジ</strong>を応援します！
            </p>
            <p className={styles.productionSupportText}>
              ホームページ、ランディングページ、ロゴなどの制作はもちろん、<strong>Webサイトおまかせコース</strong>では、<strong>月に1度のヒアリング＆コーチング</strong>で事業の近況や今後の目標をお聞きし、<strong>SNS運用方法のご提案</strong>や<strong>事業を成長させるためのアドバイス</strong>などもフォローアップいたします。
            </p>
            <p className={styles.productionSupportText}>
              テンプレートではない<strong>100%オーダーメイドのデザイン</strong>で、<strong>ブランディングの加速</strong>をお手伝いします。
            </p>
            <div className={styles.productionSupportStaff}>
              <StaticImage
                className={styles.productionSupportStaffImage}
                alt="Ami"
                src="../images/staff-ami.png"
              />
              <StaticImage
                className={styles.productionSupportStaffImage}
                alt="Ami"
                src="../images/staff-shoei.png"
              />
            </div>
          </div>
          <Link to="/past-work" className={styles.productionSupportStaffButton}>
            制作実績を見る
          </Link>
        </div>
        <StaticImage
          className={styles.productionBorderImage}
          alt="Border line"
          src="../images/curve-moss-bottom.png"
        />
        {/* <div className={styles.productionLineWrapper}>
          <p className={styles.productionLineText}>まずは気軽に</p>
          <a className={styles.productionLineButton} href="https://lin.ee/IBmGSyR" target="_blank" rel="noopener noreferrer">
            無料でLINE相談してみる
          </a>
        </div> */}
      </div>
    </Layout>
  )
}

export default Production
