// extracted by mini-css-extract-plugin
export var productionBorderImage = "production-module--production-border-image--6AC4F";
export var productionContainer = "production-module--production-container---9e4K";
export var productionCourseCard = "production-module--production-course-card--HiJO4";
export var productionCoursePoint = "production-module--production-course-point--HTRgf";
export var productionCoursePointBody = "production-module--production-course-point-body--X2Puz";
export var productionCoursePointBodyIcon = "production-module--production-course-point-body-icon--5Mhmh";
export var productionCoursePointBodyIconWrapper = "production-module--production-course-point-body-icon-wrapper--A1Wa6";
export var productionCoursePointBodyRow = "production-module--production-course-point-body-row--ztWzo";
export var productionCoursePointBodyText = "production-module--production-course-point-body-text--fvAcm";
export var productionCoursePointCushion = "production-module--production-course-point-cushion--QUAht";
export var productionCoursePointHeader = "production-module--production-course-point-header--9P0Ob";
export var productionCoursePointText = "production-module--production-course-point-text--pLJEv";
export var productionCourseTag = "production-module--production-course-tag---6IFY";
export var productionCourseTagIcon = "production-module--production-course-tag-icon--oOLbv";
export var productionCourseTagText = "production-module--production-course-tag-text---LISW";
export var productionCourseTagsGrid = "production-module--production-course-tags-grid--NA5bx";
export var productionCourseTitle = "production-module--production-course-title--bkhP2";
export var productionCourseWrapper = "production-module--production-course-wrapper--Wl9AY";
export var productionDescriptionImage = "production-module--production-description-image--9fZnk";
export var productionDescriptionLower = "production-module--production-description-lower--kOoQ1";
export var productionDescriptionNotice = "production-module--production-description-notice--E6slk";
export var productionDescriptionNoticeLink = "production-module--production-description-notice-link--u50Ti";
export var productionDescriptionRow = "production-module--production-description-row--IB55a";
export var productionDescriptionText = "production-module--production-description-text--BF7fe";
export var productionDescriptionTip = "production-module--production-description-tip--pz+u3";
export var productionDescriptionTipIcon = "production-module--production-description-tip-icon--Z21PE";
export var productionDescriptionTitle = "production-module--production-description-title--oaN+-";
export var productionDescriptionUpper = "production-module--production-description-upper--pcJRI";
export var productionDescriptionWrapper = "production-module--production-description-wrapper--pjGLq";
export var productionLineButton = "production-module--production-line-button--ns9VU";
export var productionLineButtonWrapper = "production-module--production-line-button-wrapper--uBXcX";
export var productionLineText = "production-module--production-line-text--MvhhJ";
export var productionLineWrapper = "production-module--production-line-wrapper--O7OtL";
export var productionOptionGrid = "production-module--production-option-grid--ViDKr";
export var productionOptionLeft = "production-module--production-option-left--bq6Yn";
export var productionOptionNotice = "production-module--production-option-notice--BLWI3";
export var productionOptionPastWork = "production-module--production-option-past-work--rMKh1";
export var productionOptionPastWorkButton = "production-module--production-option-past-work-button--4FNc+";
export var productionOptionPastWorkImage = "production-module--production-option-past-work-image--IUv58";
export var productionOptionTable = "production-module--production-option-table--axEmC";
export var productionOptionTableBodyItem = "production-module--production-option-table-body-item--2msvf";
export var productionOptionTableBodyPrice = "production-module--production-option-table-body-price--Hx+PM";
export var productionOptionTableHeader = "production-module--production-option-table-header--Oca-M";
export var productionOptionTableHeaderText = "production-module--production-option-table-header-text--TyKgA";
export var productionOptionTitle = "production-module--production-option-title--+1rvP";
export var productionPriceBodyIcon = "production-module--production-price-body-icon--F81U+";
export var productionPriceBodyIconWrapper = "production-module--production-price-body-icon-wrapper--2Jn9P";
export var productionPriceBodyRow = "production-module--production-price-body-row--wR4D7";
export var productionPriceBodyText = "production-module--production-price-body-text--ABk5y";
export var productionPriceBodyTextLarge = "production-module--production-price-body-text-large--riM3a";
export var productionPriceButton = "production-module--production-price-button--L6phj";
export var productionPriceButtonRow = "production-module--production-price-button-row--PZQJU";
export var productionPriceCard = "production-module--production-price-card--jBC53";
export var productionPriceHr = "production-module--production-price-hr--iI2Zl";
export var productionPriceLabel = "production-module--production-price-label--jVM-n";
export var productionPriceLabelText = "production-module--production-price-label-text--2Spp-";
export var productionPricePopup = "production-module--production-price-popup--f7AFa";
export var productionPricePopupCushion = "production-module--production-price-popup-cushion--KeV62";
export var productionPricePopupText = "production-module--production-price-popup-text--M+gyh";
export var productionPricePopupWrapper = "production-module--production-price-popup-wrapper--Rb1dH";
export var productionPriceText = "production-module--production-price-text--r8MHl";
export var productionSupport = "production-module--production-support--8F5as";
export var productionSupportInner = "production-module--production-support-inner--2M+tC";
export var productionSupportStaff = "production-module--production-support-staff--yfsmk";
export var productionSupportStaffButton = "production-module--production-support-staff-button--pP9Va";
export var productionSupportStaffImage = "production-module--production-support-staff-image--PYDae";
export var productionSupportText = "production-module--production-support-text--58kK2";
export var productionSupportTitle = "production-module--production-support-title--hOjxN";
export var productionTitleEn = "production-module--production-title-en--t1znI";
export var productionTitleJp = "production-module--production-title-jp--s3t8U";